import { css } from '@emotion/react';

const ImageStyled = css`
  width: 100%;
  background-color: transparent;

  &.close {
    width: 60%;
    height: 60%;
  }

  &.facebook {
    max-width: 54px;
  }

  &.whatsapp {
    width: 78%;
    height: 78%;
  }

  &.clipboard {
    width: 90%;
    height: 90%;
    margin-right: 15px;
  }

  &.tutorial {
    margin-right: 0;
    padding: 0;
  }

  &.help {
    width: 27px !important;
    height: 27px !important;
  }

  &.twitter {
    width: 118%;
    height: 118%;
    transform: translateY(1px);
  }
`;

export default ImageStyled;
