import { css } from '@emotion/react';
import theme from './theme';

/** Global styles */

const globalStyles = css`
  @font-face {
    font-family: 'futura-pt';
    src: url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'futura-pt';
    src: url('https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('woff'),
      url('https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }

  //Mobile, Tablet styles
  * {
    box-sizing: border-box;
    padding: 0;
    border: 0;
  }

  html {
    min-height: 100%;
    min-width: 100%;
  }

  h1 {
    font-size: 5rem;
    color: ${theme.colors.white};
    font-family: 'futura-pt';
    font-weight: 400;
  }

  body {
    font-size: 16px;
    font-family: 'futura-pt';
    margin: 0;
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  ul {
    padding: 0;
  }
  h2,
  h3 {
    margin: 0;
  }

  .flex {
    display: flex !important;
  }
  .wrap {
    flex-wrap: wrap;
  }

  .column {
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .space-between {
    justify-content: space-between;
  }
  .space-around {
    justify-content: space-around;
  }
  .content-center {
    justify-content: center;
  }
  .flex-end {
    justify-content: flex-end;
  }
  .align-items-center {
    align-items: center;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .HIDDEN {
    display: none !important;
  }

  .cursorPointer {
    cursor: pointer;
  }

  @media only screen and (min-device-width: ${theme.tablet}) {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .btn {
      margin: 3rem 2rem;
      font-size: 15px;
    }

    .cards {
      flex-wrap: wrap;
      column-gap: ${theme.spacing.s};
      row-gap: ${theme.spacing.s};
      position: relative;
    }

    .buttonBox {
      column-gap: ${theme.spacing.s};
      justify-content: center;
    }

    .cardWrapper {
      &.makeModal {
      }
    }

    .cardWrapper {
      &.makeModal {
        height: 500px;
        width: 90vw;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        grid-auto-flow: column;

        &:hover {
          overflow-x: scroll;
        }
      }
    }

    .hiddenTabletUp {
      display: none;
    }
  }

  @media only screen and (min-device-width: ${theme.desktop}) {
    .cardWrapper {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .hiddenDesktopUp {
      display: none;
    }
  }

  @media only screen and (min-device-width: ${theme.desktop_break_1}) {
    .cardWrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }
`;
export default globalStyles;
