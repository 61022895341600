/* eslint-disable react/no-unknown-property */
import Image from '../../ImageComponent/Image';
import { createLinkToShareScore } from '../../../Utils/chartMath';
import IconComponentStyled from './IconComponent.styled';

function IconComponent({ onShareButtonClick, platform, score, chartContenfulData, parentURL, categoryId, message }) {
  return (
    <IconComponentStyled className="socialIcon">
      {platform === 'facebook' && (
        <button onClick={() => onShareButtonClick(platform)} className="icon" type="button">
          <Image className="svg facebook" src="/icons/facebook-new.svg" alt="facebook logo" />
        </button>
      )}
      {platform === 'twitter' && (
        <>
          <a
            href={`https://twitter.com/intent/tweet?text=${createLinkToShareScore({
              score,
              chartContenfulData,
              platform,
              categoryId,
              parentURL,
              message,
            })}`}
            className="twitter-share-button icon"
            data-show-count="false"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="svg twitter" src="/icons/Twitter-new.svg" alt="facebook logo" />
          </a>
          <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
        </>
      )}
      {platform === 'whatsapp' && (
        <a
          href={`whatsapp://send?text=${createLinkToShareScore({ score, chartContenfulData, platform, categoryId, parentURL, message })}`}
          data-action="share/whatsapp/share"
          target="_blank"
          alt="test"
          rel="noreferrer"
          className="icon"
        >
          <Image className="svg whatsapp" src="/icons/whatsapp-final.svg" alt="whatsapp logo" />
        </a>
      )}
      {platform === 'clipboard' && (
        <button onClick={() => onShareButtonClick(platform)} alt="social media icon" href="/" className="icon" type="button">
          <Image className="svg clipboard" src="/icons/Clipboard-new.svg" alt="shareButton logo" />
        </button>
      )}
    </IconComponentStyled>
  );
}

export default IconComponent;
