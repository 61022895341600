import { useEffect } from 'react';
import { calculateScore } from '../Utils/chartMath';

const useSetScoreLabelNextLabelPoint = ({
  setScore,
  userPoints,
  setNextLabelPoint,
  labels,
  dataSetLong,
  labelPoints,
  setShowModal,
  userHasFinished,
  delay,
  setCountHowManyTimesPanelHasBeenShow,
  countHowManyTimesPanelHasBeenShow,
  chartContenfulData,
}) => {
  useEffect(() => {
    if (userPoints?.length > labels?.length) return;
    if (userPoints?.length === labels?.length && userHasFinished && countHowManyTimesPanelHasBeenShow === 0) {
      setShowModal(false);
      setTimeout(() => {
        const { score, diffenceArrayInPercentages } = calculateScore(userPoints, dataSetLong, chartContenfulData?.yMax, chartContenfulData?.yMin);
        setScore({ score, diffenceArrayInPercentages });
        setShowModal(true);
        setCountHowManyTimesPanelHasBeenShow(countHowManyTimesPanelHasBeenShow + 1);
      }, delay);
      return;
    }
    if (userPoints.length === labels.length) {
      setShowModal(true);
    }
    setNextLabelPoint(labelPoints[userPoints?.length]);
  }, [
    dataSetLong,
    labelPoints,
    setNextLabelPoint,
    setScore,
    userPoints,
    setShowModal,
    labels,
    userHasFinished,
    delay,
    setCountHowManyTimesPanelHasBeenShow,
    countHowManyTimesPanelHasBeenShow,
    chartContenfulData.yMax,
    chartContenfulData.yMin,
  ]);
};

export default useSetScoreLabelNextLabelPoint;
