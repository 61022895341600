/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */
import { Global, ThemeProvider } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import Chart from '../Chart/Chart';
import globalStyles from '../../globalStyles/Global';
import theme from '../../globalStyles/theme';
import AppStyled from './App.styled';
import Modal from '../Modal/Modal';
import GIFComponent from '../GIFComponent/GIFComponent';
/** @jsxImportSource @emotion/react */
import useGetContentfulData from '../../hooks/useGetContentfulData';
import useSetMatomoInfo from '../../hooks/useSetMatomoInfo';
import useDefineIsPortrait from '../../hooks/useDefineIsPortrait';
import useDefineWindowWidthAndHeight from '../../hooks/useDefineWindowWidthAndHeight';
import useCheckBrowser from '../../hooks/useCheckBrowser';
import useGetContentfulMedia from '../../hooks/useGetContentfulMedia';
import getIframeParams from '../../Utils/getIframeParams';
// import useSetPostMessage from '../../hooks/useSetPostMessage';

const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
function App() {
  // Game States
  const [score, setScore] = useState();
  const [showModal, setShowModal] = useState(false);
  const [userHasFinished, setUserHasFinished] = useState(false);
  const [restartGame, setRestartGame] = useState(false);
  const [countHowManyTimesPanelHasBeenShow, setCountHowManyTimesPanelHasBeenShow] = useState(0);
  const [chartContenfulData, setChartContenfulData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [isCurveBeingBuild, setIsCurveBeingBuild] = useState(false);
  const isSafari = useCheckBrowser().browserName.toLocaleLowerCase() === 'safari';

  // Position/Screen Mode and Width and Height (landscape, portrait) and Touch Devide or not.
  const [isPortrait, setIsPortrait] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [chartKey, setChartKey] = useState();

  useDefineWindowWidthAndHeight({ setWidth, setHeight });
  useDefineIsPortrait({
    width,
    setIsPortrait,
    isSafari,
    isTouchDevice,
    height,
  });

  const targetRef = useRef();

  const params = getIframeParams();

  const { categoryId } = useParams();

  useEffect(() => {
    if (!userHasFinished && countHowManyTimesPanelHasBeenShow === 0) {
      setChartKey(width);
    }
  }, [countHowManyTimesPanelHasBeenShow, userHasFinished, width]);

  const { parentURL } = useSetMatomoInfo({ categoryId });

  // Gett all Chart Data
  useGetContentfulData({ setChartContenfulData, setErrorMessage, categoryId });

  const onLoadHandler = () => {
    setTimeout(() => {
      window.parent?.postMessage(
        {
          sentinel: 'dpa',
          type: 'embed-size',
          embed: params.id,
          height: targetRef?.current?.offsetHeight,
        },
        '*',
      );
    }, 50);
  };

  const [message, setMessage] = useState('');
  useEffect(() => {
    window.addEventListener(
      'message',
      e => {
        console.log('e.data', e.data);
        if (e.data.includes('http') || e.data.includes('www')) {
          setMessage(e.data);
        }
      },
      false,
    );
  }, []);

  const { GIFUrl } = useGetContentfulMedia(process.env.REACT_APP_CONTENTFUL_CONTENT_MEDIA_TURN_YOUR_PHONE);

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <AppStyled
        ref={targetRef}
        className={clsx(
          userHasFinished && !isCurveBeingBuild && showModal && !isPortrait && 'resultPanelContext',
          'appContainerComponent',
          errorMessage?.length > 0 && 'centerError',
        )}
        id="app"
        onLoad={() => onLoadHandler()}
      >
        {errorMessage && <h2>{errorMessage}</h2>}
        {isPortrait && <GIFComponent width={width} height={height} className="turnYourPhone" key={isPortrait} src={`https:${GIFUrl}`} alt="turn your phone" />}
        {showModal && !isPortrait && Object.keys(chartContenfulData)?.length > 0 && !isCurveBeingBuild && (
          <Modal
            score={score}
            setUserHasFinished={setUserHasFinished}
            userHasFinished={userHasFinished}
            setRestartGame={setRestartGame}
            setShowModal={setShowModal}
            setCountHowManyTimesPanelHasBeenShow={setCountHowManyTimesPanelHasBeenShow}
            showModal={showModal}
            chartContenfulData={chartContenfulData}
            parentURL={parentURL}
            isPortrait={isPortrait}
            width={width}
            categoryId={categoryId}
            message={message}
          />
        )}
        {Object.keys(chartContenfulData)?.length > 0 && !errorMessage && (
          <Chart
            setScore={setScore}
            setShowModal={setShowModal}
            restartGame={restartGame}
            userHasFinished={userHasFinished}
            setRestartGame={setRestartGame}
            setCountHowManyTimesPanelHasBeenShow={setCountHowManyTimesPanelHasBeenShow}
            countHowManyTimesPanelHasBeenShow={countHowManyTimesPanelHasBeenShow}
            chartContenfulData={chartContenfulData}
            isTouchDevice={isTouchDevice}
            width={width}
            height={height}
            key={chartKey}
            showModal={showModal}
            setIsCurveBeingBuild={setIsCurveBeingBuild}
            isCurveBeingBuild={isCurveBeingBuild}
            isPortrait={isPortrait}
            categoryId={categoryId}
          />
        )}
      </AppStyled>
    </ThemeProvider>
  );
}

export default App;
