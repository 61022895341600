import styled from '@emotion/styled';

const GIFComponentStyled = styled.aside`
  &.tutorial {
    position: absolute;
    width: 100%;
    height: 90%;
    left: 0;
    background-color: white;
    justify-content: center;
    align-items: end;
    margin-top: -10px;
    display: flex;
    margin-top: -25px;

    .tutorialContainer {
      width: 80%;
      height: 100%;
    }
  }

  &.turnYourPhone {
    width: 75%;
    margin: auto;
  }

  @media only screen and (min-device-width: 480px) and (min-device-height: 450px) and (orientation: landscape) {
    .tutorialContainer {
      width: 100%;
    }
  }
`;

export default GIFComponentStyled;
