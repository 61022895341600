import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const animation = keyframes`
    from {

      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
`;

const ButtonStyled = styled.button`
  &.openButton {
    background: ${({ theme }) => theme.colors.grey700};
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    position: absolute;
  }

  &.tutorialButton {
    .help {
      display: flex;
      align-items: center;
      border-radius: 50%;
      margin-top: 1px;
      animation: ${animation} 1s infinite alternate;
    }
  }

  &.closeButton {
    justify-self: flex-end;
    align-self: center;
  }

  &.reload {
    display: flex;
    align-items: center;
    .svg {
      width: 27px;
      height: 27px;
    }
  }

  .svg {
    width: 25px;
    height: 25px;
  }

  @media only screen and (min-device-width: ${({ theme }) => theme.tablet}) {
    .svg {
      width: 35px;
    }
  }
`;

export default ButtonStyled;
