/* eslint-disable max-len */
import { useParams } from 'react-router-dom';
import { createLinkToShareScore } from '../../Utils/chartMath';
import isDevEnvironment from '../../Utils/isDevEnvironment';
import IconComponent from './IconComponent/IconComponent';
import SocialMediaStyled from './SocialMedia.styled';

function SocialMedia({ score, chartContenfulData, parentURL, message }) {
  const { categoryId } = useParams();

  const onShareButtonClick = async platform => {
    await navigator.clipboard.writeText(decodeURIComponent(createLinkToShareScore({ score, chartContenfulData, platform, categoryId, parentURL, message })));
    const textToDisplayInAlert =
      platform === 'facebook'
        ? 'Das Ergebnis wurde in die Zwischenablage kopiert. Von dort kann es vor dem Teilen in den Facebook-Beitrag eingefügt werden. Ein Klick auf "OK" öffnet Facebook in einem neuen Tab.'
        : 'Copied to clipboard';

    // eslint-disable-next-line no-alert
    window.alert(textToDisplayInAlert);
    if (platform === 'facebook') {
      if (isDevEnvironment) {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${message || parentURL}`, '_blank');
      } else {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(message || parentURL)}`, '_blank');
      }
    }
  };
  return (
    <SocialMediaStyled className="social">
      <IconComponent
        onShareButtonClick={onShareButtonClick}
        platform="facebook"
        score={score}
        chartContenfulData={chartContenfulData}
        parentURL={parentURL}
        categoryId={categoryId}
        message={message}
      />
      <IconComponent
        onShareButtonClick={onShareButtonClick}
        platform="twitter"
        score={score}
        chartContenfulData={chartContenfulData}
        parentURL={parentURL}
        categoryId={categoryId}
        message={message}
      />
      <IconComponent
        onShareButtonClick={onShareButtonClick}
        platform="whatsapp"
        score={score}
        chartContenfulData={chartContenfulData}
        parentURL={parentURL}
        categoryId={categoryId}
        message={message}
      />
      <IconComponent
        onShareButtonClick={onShareButtonClick}
        platform="clipboard"
        score={score}
        chartContenfulData={chartContenfulData}
        parentURL={parentURL}
        categoryId={categoryId}
        message={message}
      />
    </SocialMediaStyled>
  );
}

export default SocialMedia;
