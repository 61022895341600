import clsx from 'clsx';
import { useEffect } from 'react';
import ButtonStyled from './Button.styled';
import Image from '../ImageComponent/Image';

function ModalTogglerButton({ setShowModal, showModal, className }) {
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const enterFunction = e => {
      if (e.key === 'Enter' && !showModal) {
        setShowModal(true);
      }
    };
    document.addEventListener('keydown', enterFunction, false);
    return () => document.removeEventListener('keydown', enterFunction);
  }, [setShowModal, showModal]);

  return (
    <ButtonStyled onClick={toggleModal} type="button" className={clsx(showModal ? 'closeButton' : 'openButton', className === 'openButton')}>
      {showModal ? <Image className="svg" src="/icons/Close-new.svg" alt="close button" /> : 'Ergebnis teilen'}
    </ButtonStyled>
  );
}

export default ModalTogglerButton;
