import { useEffect } from 'react';

const useDefineWindowWidthAndHeight = ({ setWidth, setHeight }) => {
  useEffect(() => {
    const updateSize = () => {
      setWidth(window?.innerWidth ? window?.innerWidth : window?.screen?.width);
      setHeight(window?.innerHeight ? window?.innerHeight : window?.screen?.height);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWidth, setHeight]);
};

export default useDefineWindowWidthAndHeight;
