import clsx from 'clsx';
import { useEffect } from 'react';
import ModalStyled from './Modal.styled';
import { cutLabelsInHalf, mutateResultsToEmojis } from '../../Utils/chartMath';
import Buttons from '../Buttons/Buttons';
import ModalTogglerButton from '../Button/ModalTogglerButton';
import SocialMedia from '../SocialMedia/SocialMedia';
import Image from '../ImageComponent/Image';
import Tooltip from '../Tooltip/Tooltip';

function Modal({ score, userHasFinished, setUserHasFinished, setRestartGame, setShowModal, showModal, chartContenfulData, parentURL, categoryId, message }) {
  const labels = chartContenfulData?.labels;
  const index = cutLabelsInHalf(labels);
  const cutLabels = labels?.slice(index, labels?.length);

  useEffect(() => {
    const escFunction = e => {
      if (e.key === 'Escape') {
        setShowModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction);
  }, [setShowModal]);

  return (
    <ModalStyled className="modalComponentContainer">
      <div className={clsx(userHasFinished && 'resultPanelContext', 'innerModal')}>
        {!userHasFinished && (
          <Buttons setUserHasFinished={setUserHasFinished} setRestartGame={setRestartGame} setShowModal={setShowModal} className="buttons" />
        )}
        {userHasFinished && (
          <div className="modalContentContainer">
            <Tooltip tooltipText="Feedback" className="feedback">
              <span className="emailWrapper">
                <a href="mailto:linechallenge@dpa-info.com">
                  <Image className="svg" src="/icons/Email-new.svg" alt="email us" />
                </a>
              </span>
            </Tooltip>
            <ModalTogglerButton setShowModal={setShowModal} showModal={showModal} />

            <h2 className="resultTitle">Dein Ergebnis</h2>

            <div className="emojiContainer">
              {score?.diffenceArrayInPercentages?.map((value, indexOfMap) => (
                <div className="labelEmojiContainer" key={Math.random(score?.diffenceArrayInPercentages)}>
                  <Tooltip tooltipText={value} className="result">
                    <p className="emojiIcon">
                      <span className="svg">{mutateResultsToEmojis(value)}</span>
                    </p>
                    {cutLabels?.length && <span className="labelName">{cutLabels[indexOfMap]}</span>}
                  </Tooltip>
                </div>
              ))}
            </div>

            <div className="socialMediaWrapper">
              <p className="socialTitle">Fordere deine Freunde heraus:</p>
              <SocialMedia score={score} chartContenfulData={chartContenfulData} parentURL={parentURL} className="socialMedia" message={message} />
            </div>
            {!categoryId && (
              <p className="footer">
                <span className="comeTomorrow">Neue Challenge?</span> Komm&apos; morgen wieder!
              </p>
            )}
          </div>
        )}
      </div>
    </ModalStyled>
  );
}

export default Modal;
