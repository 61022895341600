import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './Components/App/App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:categoryId" element={<App />} />
      <Route path="*" element={<App />} />
    </Routes>
  </BrowserRouter>,
);
