/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { client } from './useGetContentfulData';

const useGetContentfulMedia = assetID => {
  const [GIFUrl, setGIFUrl] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const contentfulData = await client?.getAsset(assetID);
        if (Object.keys(contentfulData)?.length > 0) {
          setGIFUrl(contentfulData.fields.file.url);
          setLoading(false);
        } else {
          throw new Error("Contentful error for GIF's");
        }
      } catch (e) {
        setError('Error While Fetching Data from Contentful');
        console.log('error', e);
      }
    };
    fetchData();
  }, [assetID]);

  return { GIFUrl, loading, error };
};

export default useGetContentfulMedia;
