import { useEffect } from 'react';

const useSetChartDynamicData = ({ chartDynamicData, dataSetLong, userHasFinished, setChartDynamicData, lag, setIsCurveBeingBuild }) => {
  useEffect(() => {
    if (chartDynamicData.length < dataSetLong?.length && userHasFinished) {
      setIsCurveBeingBuild(true);
      setTimeout(() => {
        setChartDynamicData(pointsArray => {
          const newPoints = [...pointsArray];
          newPoints.push(dataSetLong[pointsArray.length]);
          return newPoints;
        });
      }, lag);
      return;
    }
    setTimeout(() => {
      setIsCurveBeingBuild(false);
    }, lag);
  }, [chartDynamicData, dataSetLong, lag, setChartDynamicData, setIsCurveBeingBuild, userHasFinished]);
};

export default useSetChartDynamicData;
