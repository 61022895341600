import styled from '@emotion/styled';

const ButtonsStyled = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(0, 0, 0, 0);
  align-items: center;
  margin-bottom: 2rem;

  .btn {
    background: ${({ theme }) => theme.colors.grey700};
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    padding: 0.5rem 0.5rem;
    font-size: 14px;
    border-radius: 5px;
    margin: 0 1rem;
  }
`;

export default ButtonsStyled;
