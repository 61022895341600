import { useEffect, useState, useRef, useCallback } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, defaults } from 'chart.js';
import { Line } from 'react-chartjs-2';
import clsx from 'clsx';
import FontFaceObserver from 'fontfaceobserver';
import { cutLabelsInHalf, calculateLabelPositions, calculateDelayToShowModal } from '../../Utils/chartMath';

import { chartConfig, defineChartDefaultVariables } from '../../Utils/chartConfig';
import ChartFooter from './ChartFooter';
import ChartStyled from './Chart.styled';
import useDatasets from '../../hooks/useDatasets';
import useSetScoreNextLabelPoint from '../../hooks/useSetScore&SetNextLabelPoint';
import useGetChartOptions from '../../hooks/useGetChartOptions';
import ChartTitleAndSubtitle from '../ChartTitle&Subtitle/ChartTitle&Subtitle';
import useSetChartDynamicData from '../../hooks/useSetChartDynamicData';
import GIFComponent from '../GIFComponent/GIFComponent';
import Image from '../ImageComponent/Image';
import useGetContentfulMedia from '../../hooks/useGetContentfulMedia';
import ModalTogglerButton from '../Button/ModalTogglerButton';
import useSetDynamicInitialPoints from '../../hooks/setDynamicInitialPoints';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

defineChartDefaultVariables(defaults);

function Chart({
  setScore,
  setShowModal,
  restartGame,
  userHasFinished,
  setRestartGame,
  setCountHowManyTimesPanelHasBeenShow,
  countHowManyTimesPanelHasBeenShow,
  chartContenfulData,
  isTouchDevice,
  width,
  setIsCurveBeingBuild,
  isCurveBeingBuild,
  showModal,
  isPortrait,
  height,
}) {
  const ref = useRef(null);
  const labels = chartContenfulData?.labels;
  const dataSetLong = chartContenfulData?.values;
  const dataShort = dataSetLong?.slice(0, cutLabelsInHalf(labels));

  const { lag, name: chartName, headerDescription, source } = chartContenfulData;

  const [currentDataSet] = useState({
    curveOne: dataShort,
    curveTwo: dataShort,
  });
  const [userPoints, setUserPoints] = useState(dataShort);
  const [chartData, setChartData] = useState({});
  const [labelPoints, setLabelPoints] = useState([]);
  const [nextLabelPoint, setNextLabelPoint] = useState();
  const [isMouseOverLabel, setIsMouseOverLabel] = useState(false);
  const [projectedLineValue, setProjectedLineValue] = useState({ value: null });
  const [chartConfigValues] = useState(chartConfig);
  const [chartDynamicData, setChartDynamicData] = useState(dataShort);
  const [demoDynamicData, setDemoDynamicData] = useState({ value: null });
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [userHasPlayed, setUserHasPlayed] = useState(false);
  const [initialCurveIsBuild, setInitialCurveIsBuild] = useState(false);
  const [userHasHoveredOverTheChart, setUserHasHoveredOverTheChart] = useState(false);
  const [dynamicInitialPoints, setDynamicInitialPoints] = useState([]);

  useEffect(() => {
    if (initialCurveIsBuild) {
      const { numberOfDecimalsInUserPoints, yMin, yMax } = chartContenfulData;
      const rangeY = yMax - yMin;
      let counter = 0;
      const valueToDeductPerTimeUnit = 1 / 10 ** numberOfDecimalsInUserPoints;
      const lastShownValue = parseFloat(chartContenfulData.values[cutLabelsInHalf(chartContenfulData.labels) - 1], 10);
      const animationInterval = setInterval(() => {
        if (counter * valueToDeductPerTimeUnit <= rangeY) {
          setDemoDynamicData({
            value: chartContenfulData.yMax - counter * valueToDeductPerTimeUnit,
          });
        } else {
          clearInterval(animationInterval);
          setDemoDynamicData({ value: lastShownValue });
        }
        counter += 1;
      }, 10);
    }
  }, [chartContenfulData, chartContenfulData.labels, chartContenfulData.values, initialCurveIsBuild]);

  useEffect(() => {
    const clickFunction = () => {
      if (!initialCurveIsBuild) {
        setTimeout(() => setInitialCurveIsBuild(true), 200);
      }
    };
    document.addEventListener('click', clickFunction, false);
    return () => document.removeEventListener('click', clickFunction);
  }, [chartContenfulData, chartContenfulData.values, initialCurveIsBuild]);

  const delay = calculateDelayToShowModal(dataSetLong, lag, labels);

  useSetDynamicInitialPoints({
    dynamicInitialPoints,
    currentDataSet,
    userHasPlayed,
    setDynamicInitialPoints,
    initialCurveIsBuild,
    setInitialCurveIsBuild,
  });

  const datasets = useDatasets({
    userPoints,
    chartConfigValues,
    currentDataSet,
    isMouseOverLabel,
    projectedLineValue,
    labels,
    dataSetLong,
    userHasFinished,
    chartDynamicData,
    dynamicInitialPoints,
    initialCurveIsBuild,
    demoDynamicData,
    userHasPlayed,
    userHasHoveredOverTheChart,
  });

  useSetChartDynamicData({
    chartDynamicData,
    dataSetLong,
    userHasFinished,
    setChartDynamicData,
    lag,
    setIsCurveBeingBuild,
  });

  useSetScoreNextLabelPoint({
    setScore,
    userPoints,
    setNextLabelPoint,
    labels,
    chartConfig,
    dataSetLong,
    labelPoints,
    setShowModal,
    userHasFinished,
    delay,
    setCountHowManyTimesPanelHasBeenShow,
    countHowManyTimesPanelHasBeenShow,
    chartContenfulData,
  });

  useEffect(() => {
    if (userPoints.length > dataShort.length) {
      setUserHasPlayed(true);
    }
  }, [dataShort, restartGame, setRestartGame, setShowModal, userPoints, userPoints.length]);

  useEffect(() => {
    if (restartGame) {
      setUserPoints(dataShort.map(data => data));
      setShowModal(false);
      setRestartGame(false);
    }
  }, [dataShort, restartGame, setRestartGame, setShowModal]);

  const getChartData = useCallback(
    () => ({
      id: 'getChartData',
      beforeDraw(chart) {
        const font = new FontFaceObserver(chartConfig.fonts.family);
        font.load().then(() => setChartData(chart));
      },
    }),
    [],
  );

  useEffect(() => {
    if (Object.keys(chartData).length === 0) {
      return;
    }
    setLabelPoints([...calculateLabelPositions(chartData, labels)]);
  }, [chartData, labels, width]);

  const { ...chartOptions } = useGetChartOptions({
    setUserPoints,
    userPoints,
    labels,
    setIsMouseOverLabel,
    isMouseOverLabel,
    nextLabelPoint,
    chartData,
    setProjectedLineValue,
    width,
    projectedLineValue,
    isTouchDevice,
    chartContenfulData,
    userHasFinished,
    chartDynamicData,
    showModal,
    isCurveBeingBuild,
    userHasPlayed,
    initialCurveIsBuild,
    setUserHasHoveredOverTheChart,
    userHasHoveredOverTheChart,
  });

  const contentfulID = isTouchDevice
    ? process.env.REACT_APP_CONTENTFUL_CONTENT_MEDIA_TUTORIA_MOBILE
    : process.env.REACT_APP_CONTENTFUL_CONTENT_MEDIA_TUTORIA_DESKTOP;
  const { GIFUrl } = useGetContentfulMedia(contentfulID);

  return (
    <ChartStyled
      className={clsx(
        'chartComponentContainer',
        userHasFinished && 'resultPanelContext',
        showTutorialModal && 'tutorialModelContext',
        isPortrait && 'HIDDEN',
        showModal && userHasFinished && 'modalContext',
      )}
    >
      {datasets.length > 0 && (
        <div className="chartComponentInnerWrapper">
          <ChartTitleAndSubtitle
            name={chartName}
            headerDescription={headerDescription}
            chartContenfulData={chartContenfulData}
            setRestartGame={setRestartGame}
            userHasFinished={userHasFinished}
            showModal={showModal}
            setShowTutorialModal={setShowTutorialModal}
            showTutorialModal={showTutorialModal}
            userHasPlayed={userHasPlayed}
            width={width}
            height={height}
          />
          <figure
            className={clsx(
              'chartComponent',
              userHasFinished && !isPortrait && countHowManyTimesPanelHasBeenShow > 0 && 'flex align-items-center justify-center',
            )}
          >
            {!showModal && userHasFinished && !isPortrait && countHowManyTimesPanelHasBeenShow > 0 && (
              <ModalTogglerButton setShowModal={setShowModal} showModal={showModal} className="openButton" />
            )}
            <Line
              id="chartOptions"
              options={chartOptions}
              data={{
                labels,
                datasets,
              }}
              plugins={[getChartData()]}
              ref={ref}
              aria-label="Chart Component"
              role="img"
              className={clsx(isMouseOverLabel && 'cursorPointer')}
            />
          </figure>
          <ChartFooter source={source} userHasFinished={userHasFinished} isCurveBeingBuild={isCurveBeingBuild} showModal={showModal} />
        </div>
      )}
      {showTutorialModal && !userHasPlayed && (
        <div className="tutorialModalWrapper">
          <button className="closeTutorial" onClick={() => setShowTutorialModal(false)} type="button">
            <Image className="close" src="/icons/Close-new.svg" alt="close button" />
          </button>
          <GIFComponent className="tutorial" src={`https:${GIFUrl}`} isTouchDevice={isTouchDevice} alt="game tutorial" />
        </div>
      )}
    </ChartStyled>
  );
}

export default Chart;
