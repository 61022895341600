import styled from '@emotion/styled';

/** Card styles */

const ChartTitleSubtitle = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 28px;
  justify-content: center;

  .titleDescriptionWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    align-items: center;
  }

  .title {
    font-size: ${({ theme }) => theme.fontSize.small};
    margin: 0;
  }

  .buttonWrapper {
    min-width: 90px;
    justify-content: space-around;
  }

  .subTitle {
    font-size: ${({ theme }) => theme.fontSize.verySmall};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }

  @media only screen and (min-device-width: ${({ theme }) => theme.tablet}) {
    align-items: start;
    margin-left: 1rem;
    padding: 0;

    .title {
      font-size: ${({ theme }) => theme.fontSize.small};
    }

    .subTitle {
      font-size: ${({ theme }) => theme.fontSize.small};
    }
  }

  @media only screen and (min-device-width: ${({ theme }) => theme.desktop}) {
    .title {
      font-size: ${({ theme }) => theme.fontSize.medium};
    }
  }
`;

export default ChartTitleSubtitle;
