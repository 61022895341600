import styled from '@emotion/styled';

const IconComponentStyled = styled.span`
  width: 10%;
  margin: 0 ${({ theme }) => theme.spacing.xxs};

  .icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default IconComponentStyled;
