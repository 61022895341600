import { useEffect } from 'react';

const useSetDynamicInitialPoints = ({ dynamicInitialPoints, currentDataSet, initialCurveIsBuild, setDynamicInitialPoints, setInitialCurveIsBuild }) => {
  useEffect(() => {
    if (dynamicInitialPoints.length < currentDataSet?.curveOne.length && !initialCurveIsBuild) {
      const dynamicInitialPointsInterval = setTimeout(() => {
        setDynamicInitialPoints(pointsArray => {
          const newPoints = [...pointsArray];
          newPoints.push(currentDataSet?.curveOne[pointsArray.length]);
          return newPoints;
        });
        if (currentDataSet && dynamicInitialPoints.length === currentDataSet.curveOne.length - 1) {
          clearInterval(dynamicInitialPointsInterval);
          setTimeout(() => setInitialCurveIsBuild(true), 800);
        }
      }, 800);
    }
  }, [dynamicInitialPoints, setDynamicInitialPoints, currentDataSet.curveOne, initialCurveIsBuild, setInitialCurveIsBuild, currentDataSet]);
};

export default useSetDynamicInitialPoints;
