import clsx from 'clsx';
import Image from '../ImageComponent/Image';
import ChartFooterStyled from './ChartFooter.styled';

function ChartFooter({ source, userHasFinished, isCurveBeingBuild, showModal }) {
  return (
    <ChartFooterStyled className={clsx('flex space-between footer', userHasFinished && !isCurveBeingBuild && showModal && 'resultPanelContext')}>
      <Image className="svg" src="/icons/dpaLogo.svg" alt="dpa logo" />
      <span className="source">
        Quelle:&nbsp;
        {source.name}
      </span>
    </ChartFooterStyled>
  );
}

export default ChartFooter;
