import styled from '@emotion/styled';

const ModalStyled = styled.article`
  display: flex;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 1000;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;

  .innerModal {
    display: grid;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 650px;

    &.resultPanelContext {
      display: flex;
    }

    .modalContentContainer {
      min-height: 250px;
      background: rgba(255, 255, 255, 1);
      border-radius: 5px;
      padding: 0.5rem 1rem;
      width: 95vw;
      height: 100%;
      text-align: center;
      display: grid;
      -webkit-box-shadow: 5px 5px 23px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 5px 5px 23px 0px rgba(0, 0, 0, 0.25);
      grid-template-columns: minmax(25px, 1fr) minmax(125px, 11fr) minmax(25px, 1fr);
      grid-template-rows:
        minmax(50px, 0.5fr) minmax(70px, 0.4fr) minmax(95px, 1fr)
        minmax(60px, 0.5fr);
      opacity: 0.98;
      max-height: 300px;
      padding: 0.1rem 0;

      .emailWrapper {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin-left: 1rem;
        justify-self: flex-start;
        align-self: center;

        .svg {
          width: 30px;
          height: 30px;
        }
      }

      .resultTitle {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        color: ${({ theme }) => theme.colors.grey800};
        align-self: center;
        margin-bottom: 0.15rem;
      }

      .closeButton {
        justify-self: flex-end;
        align-self: center;
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        margin-right: 0.8rem;

        .svg {
          width: 35px;
          height: 35px;
        }
      }

      .emojiContainer {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        display: flex;
        justify-content: center;
        grid-template-rows: minmax(25px, 35px) 1fr;

        .labelEmojiContainer {
          min-width: 80px;
          .emojiIcon {
            padding: 0 ${({ theme }) => theme.spacing.xxs};
            margin: 0;
            font-size: 30px;

            .svg {
              width: 100%;
              height: 100%;
              text-shadow: 0px 2px 3px #000000;
            }
          }
          .labelName {
            margin: 0 0.5rem;
            display: flex;
            justify-content: center;
          }
        }
      }

      .socialMediaWrapper {
        grid-row: 3 / 4;
        grid-column: 1 / 4;
        background-color: ${({ theme }) => theme.colors.grey200};

        .socialTitle {
          margin: 0.75rem 0;
          font-size: 0.65rem;
          font-weight: ${({ theme }) => theme.fontWeight.bold};
          color: ${({ theme }) => theme.colors.grey800};
          letter-spacing: 0.025rem;
          text-transform: uppercase;
        }

        .social {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }
      }

      .footer {
        grid-column: 2 / 3;
        grid-row: 4 / 5;
        color: ${({ theme }) => theme.colors.grey800};
        font-size: ${({ theme }) => theme.fontSize.medium};
      }
    }
  }

  @media only screen and (min-device-width: 800px) {
    margin-left: 1%;
  }
`;

export default ModalStyled;
