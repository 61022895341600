import { useEffect } from 'react';

const useDefineIsPortrait = ({ isSafari, setIsPortrait, width, height }) => {
  useEffect(() => {
    if (width < 545) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
  }, [setIsPortrait, width, isSafari, height]);
};

export default useDefineIsPortrait;
