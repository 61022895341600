import clsx from 'clsx';
import TooltipStyled from './Tooltip.styled';

function Tooltip({ tooltipText, children, className }) {
  return (
    <TooltipStyled className={clsx('tooltip', className)}>
      {children}
      <span className="tooltiptext">
        {tooltipText}
        {className === 'result' && '%'}
      </span>
    </TooltipStyled>
  );
}

export default Tooltip;
