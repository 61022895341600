import { useMemo } from 'react';

const useDatasets = ({
  userPoints,
  chartConfigValues,
  isMouseOverLabel,
  projectedLineValue,
  initialCurveIsBuild,
  labels,
  dynamicInitialPoints,
  userHasFinished,
  chartDynamicData,
  currentDataSet,
  demoDynamicData,
  userHasPlayed,
  userHasHoveredOverTheChart,
}) => {
  const datasets = useMemo(() => {
    const dataArray = userPoints?.map(() => undefined);
    dataArray[userPoints.length - 1] = userPoints[userPoints.length - 1];

    const defineWhichDataSetToUse = () => {
      if (userHasFinished) {
        return chartDynamicData;
      }
      if (!initialCurveIsBuild) {
        return dynamicInitialPoints;
      }
      return currentDataSet?.curveOne;
    };

    const defineWhichDataSetToUseOnCurveThree = () => {
      if (isMouseOverLabel) {
        return [...dataArray, projectedLineValue?.value];
      }
      if (!initialCurveIsBuild) {
        return null;
      }
      if (!userHasPlayed && !userHasHoveredOverTheChart) {
        return [...dataArray, demoDynamicData.value];
      }
      return [...dataArray];
    };

    return [
      {
        ...chartConfigValues?.curveOne,
        data: defineWhichDataSetToUse(),
      },
      {
        ...chartConfigValues?.curveTwo,
        data: initialCurveIsBuild ? labels?.map((value, index) => userPoints[index]) : null,
        borderDash: [10, 5],
      },
      {
        ...chartConfigValues?.curveThree,
        data: defineWhichDataSetToUseOnCurveThree(),
        borderDash: [10, 5],
      },
    ];
  }, [
    userPoints,
    chartConfigValues?.curveOne,
    chartConfigValues?.curveTwo,
    chartConfigValues?.curveThree,
    initialCurveIsBuild,
    labels,
    userHasFinished,
    currentDataSet?.curveOne,
    chartDynamicData,
    dynamicInitialPoints,
    isMouseOverLabel,
    userHasPlayed,
    userHasHoveredOverTheChart,
    projectedLineValue?.value,
    demoDynamicData.value,
  ]);

  return datasets;
};

export default useDatasets;
