import ButtonStyled from './Button.styled';
import Image from '../ImageComponent/Image';

function RestartButton({ setRestartGame }) {
  const onStartAgain = () => {
    setRestartGame(true);
  };
  return (
    <ButtonStyled onClick={onStartAgain} type="button" className="reload">
      <Image className="svg" src="/icons/reload.svg" alt="dpa logo" />
    </ButtonStyled>
  );
}

export default RestartButton;
