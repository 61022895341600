/* eslint-disable comma-dangle */
import { useMemo } from 'react';
import throttle from 'lodash/throttle';
import { getChartOptions } from '../Utils/chartConfig';

const useGetChartOptions = ({
  userPoints,
  setUserPoints,
  setIsMouseOverLabel,
  isMouseOverLabel,
  nextLabelPoint,
  labels,
  chartData,
  setProjectedLineValue,
  width,
  isTouchDevice,
  chartContenfulData,
  userHasFinished,
  chartDynamicData,
  showModal,
  isCurveBeingBuild,
  initialCurveIsBuild,
  setUserHasHoveredOverTheChart,
  userHasHoveredOverTheChart,
}) => {
  const chartOptions = useMemo(() => {
    const value = getChartOptions(
      setUserPoints,
      userPoints,
      labels,
      setIsMouseOverLabel,
      isMouseOverLabel,
      nextLabelPoint,
      chartData,
      setProjectedLineValue,
      width,
      isTouchDevice,
      chartContenfulData,
      userHasFinished,
      chartDynamicData,
      showModal,
      isCurveBeingBuild,
      initialCurveIsBuild,
      setUserHasHoveredOverTheChart,
      userHasHoveredOverTheChart,
    );
    return {
      ...value,
      onHover: throttle(value.onHover, 10),
      animation: {
        duration: 0,
      },
    };
  }, [
    setUserPoints,
    userPoints,
    labels,
    setIsMouseOverLabel,
    isMouseOverLabel,
    nextLabelPoint,
    chartData,
    setProjectedLineValue,
    width,
    isTouchDevice,
    chartContenfulData,
    userHasFinished,
    chartDynamicData,
    showModal,
    isCurveBeingBuild,
    initialCurveIsBuild,
    setUserHasHoveredOverTheChart,
    userHasHoveredOverTheChart,
  ]);

  return chartOptions;
};

export default useGetChartOptions;
