import styled from '@emotion/styled';

const SocialMediaStyled = styled.div`
  height: 50px !important;
  display: flex;
  justify-content: center;
  height: 85%;
`;

export default SocialMediaStyled;
