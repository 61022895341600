import isDevEnvironment from './isDevEnvironment';

const roundUp = (num, precision) => {
  const newPrecision = 10 ** precision;
  return Math.ceil(num * newPrecision) / newPrecision;
};

const convertValueToPercentage = (value, maxY, minY) => (value / (maxY - minY)) * 100;

const calculateValueInChartUnits = (maxY, minY, eventY, eventChart, roundUpToDecimals = 0, offsetCorrection = 0) => {
  const rangeY = maxY - minY;
  const coeficientY = rangeY / eventChart.chartArea.height;
  const clickValueInChartUnits = eventY
    ? maxY - (eventY - eventChart.chartArea.top + offsetCorrection) * coeficientY
    : maxY - (eventChart.tooltip._eventPosition.y - eventChart.chartArea.top + offsetCorrection) * coeficientY;
  return roundUp(clickValueInChartUnits, roundUpToDecimals);
};

const isClickValidYAxis = (maxY, minY, event) => {
  const rangeY = maxY - minY;
  const coeficientY = rangeY / event.chart.chartArea.height;
  const clickValueInChartUnits = maxY - (event.y - event.chart.chartArea.top) * coeficientY;
  return clickValueInChartUnits > minY && clickValueInChartUnits < maxY;
};

const calculateLabelPositions = (chart, labels) => {
  const {
    scales: { x },
  } = chart;
  const labelXPositionByIndex = value => x.getPixelForTick(value);

  const labelsXPositionArray = [];
  for (let i = 0; i < labels.length; i += 1) {
    labelsXPositionArray.push(roundUp(labelXPositionByIndex(i), 0));
  }
  return labelsXPositionArray;
};

const createColorArray = (labels, nextLabelIndex, defaultColor, nextLabelColor) => {
  const colorArray = [];
  labels?.forEach(() => {
    colorArray.push(defaultColor);
  });
  colorArray[nextLabelIndex] = nextLabelColor;
  return colorArray;
};

const createLineWidthArray = (labels, nextLabelIndex, defaultWidth, highlightedWidth) => {
  const colorArray = [];
  labels.forEach(() => {
    colorArray.push(defaultWidth);
  });
  colorArray[nextLabelIndex] = highlightedWidth;
  return colorArray;
};

const mutateResultsToEmojis = value => {
  switch (true) {
    case value < 65:
      return '🔴';
    case value >= 65 && value < 75:
      return '🟠';
    case value >= 75 && value < 90:
      return '🟡';
    case value >= 90:
      return '🟢';
    default:
      return null;
  }
};

const cutLabelsInHalf = labels => roundUp((labels.length - 1) / 2, 0);

const calculateDelayToShowModal = (dataSetLong, lag, labels) => lag * (dataSetLong.length - dataSetLong.slice(0, cutLabelsInHalf(labels)).length + 1);

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const checkIfParentURLHasQueryString = parentURL => parentURL?.indexOf('?') !== -1;

const checkIfParentURLHasInviteBoolean = parentURL => parentURL?.indexOf('wasUserInvited=true') !== -1;

const enableTooltip = (isMouseOverLabel, userHasFinished, width, chartDynamicData, labels, showModal, isCurveBeingBuild) => {
  if (showModal) return false;
  if (width < 500) return false;
  if (userHasFinished) {
    if (chartDynamicData.length === labels.length && !isCurveBeingBuild) {
      return true;
    }
  }
  if (isMouseOverLabel) return true;
  return false;
};

const calculateScore = (userPoints, chartData, maxY, minY) => {
  const difference = [];
  chartData.map((value, index) => {
    difference.push(Math.abs(value - userPoints[index]));
    return difference;
  });
  const differenceArray = difference.splice(cutLabelsInHalf(userPoints), difference.length);
  const diffenceArrayInPercentages = differenceArray.map(value => roundUp(100 - convertValueToPercentage(value, maxY, minY), 0));

  return {
    diffenceArrayInPercentages,
  };
};

const defineTopicForComment = (categoryId, chartContenfulData) => {
  if (categoryId) {
    return chartContenfulData?.name;
  }
  return capitalizeFirstLetter(chartContenfulData?.category[0]);
};

const defineParentURL = parentURL => {
  if (checkIfParentURLHasInviteBoolean(parentURL)) {
    return parentURL;
  }
  if (checkIfParentURLHasQueryString(parentURL)) {
    return parentURL.concat('&wasUserInvited=true');
  }
  return parentURL.concat('?wasUserInvited=true');
};

const createLinkToShareScore = ({ score, chartContenfulData, platform, categoryId, parentURL, message }) => {
  let modifiedParentURL;
  if (parentURL) {
    modifiedParentURL = defineParentURL(parentURL);
  } else {
    modifiedParentURL = defineParentURL(message);
  }
  modifiedParentURL = encodeURIComponent(message);

  const emojis = score?.diffenceArrayInPercentages?.map(value => mutateResultsToEmojis(value));
  let textToDisplay;
  const editedEmojis = emojis?.join(' ');
  if (platform === 'whatsapp') {
    textToDisplay = `Mein Ergebnis bei der %23linechallenge zum Thema "${defineTopicForComment(
      categoryId,
      chartContenfulData,
    )}":%0a${editedEmojis}%0aKannst du es besser? ${modifiedParentURL}`;
  } else if (platform === 'twitter') {
    textToDisplay = `Mein Ergebnis bei der %23linechallenge zum Thema "${defineTopicForComment(categoryId, chartContenfulData)}":%0a
${editedEmojis}%0a
Kannst du es besser? ${modifiedParentURL}`;
  } else {
    textToDisplay = `Mein Ergebnis bei der #linechallenge zum Thema: "${defineTopicForComment(categoryId, chartContenfulData)}"
${editedEmojis}
Kannst du es besser? ${modifiedParentURL}`;
  }
  return textToDisplay;
};

const isDomainValid = (domain, validURLs) => {
  if (isDevEnvironment) {
    return true;
  }
  return validURLs?.includes(domain);
};

// The number that comes after the "%" is the amount of id's present in contentful.
const toRange = number => ((number - 1) % 15) + 1;

const calculateIndexForTodaysChart = (year, month, day) => {
  const initialDate = new Date(year, month, day);
  const now = Date.now();
  const difference = initialDate - now;
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const daysSince = Math.abs(Math.floor(difference / millisecondsPerDay));
  return toRange(daysSince);
};

export {
  roundUp,
  calculateScore,
  calculateValueInChartUnits,
  isClickValidYAxis,
  cutLabelsInHalf,
  calculateLabelPositions,
  createColorArray,
  createLineWidthArray,
  mutateResultsToEmojis,
  calculateDelayToShowModal,
  createLinkToShareScore,
  calculateIndexForTodaysChart,
  checkIfParentURLHasQueryString,
  isDomainValid,
  enableTooltip,
};
