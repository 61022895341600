import ButtonsStyled from './Buttons.styled';

function Buttons({ setUserHasFinished, setRestartGame, setShowModal }) {
  const onFinishClick = () => {
    setShowModal(false);
    setUserHasFinished(true);
  };

  const onStartAgain = () => {
    setRestartGame(true);
  };

  return (
    <ButtonsStyled className="buttons">
      <button className="btn" onClick={onFinishClick} type="button">
        Zur Auswertung
      </button>
      <button className="btn" onClick={onStartAgain} type="button">
        Neustart
      </button>
    </ButtonsStyled>
  );
}

export default Buttons;
