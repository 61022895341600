import styled from '@emotion/styled';

const AppStyled = styled.main`
  min-width: 100%;
  display: block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  max-width: 900px;
  max-height: 600px;

  &.resultPanelContext {
    background-color: ${({ theme }) => theme.colors.grey400};
  }

  .score {
    h2 {
      font-family: ${({ theme }) => theme.fontFamily.default};
      margin: 1rem;
      font-weight: 100;
      font-size: ${({ theme }) => theme.fontSize.big};
    }
  }

  .turnYourPhone {
    display: block;
    .tutorial {
      text-align: center;
    }
  }

  &.centerError {
    height: 100vh;
    padding: 2rem;
    text-align: center;
  }

  // Here changes the justify-content

  @media only screen and (min-device-width: 560px) {
    justify-content: center;
  }
`;

export default AppStyled;
