import styled from '@emotion/styled';

/** Card styles */

const ChartStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: 98%;
  border: 1px solid #e1e6eb;

  .chartComponentInnerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0.1rem 0.5rem 0.5rem;

    .chartComponent {
      position: static;
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }

  &.tutorialModelContext {
    background-color: ${({ theme }) => theme.colors.grey400};
    .footer {
      background-color: ${({ theme }) => theme.colors.grey400};
    }
  }

  &.modalContext {
    border: 0;
  }

  @media only screen and (min-device-width: 600px) {
    &.resultPanelContext {
      display: flex;
      align-items: center;

      .closeButton {
        display: none;
      }
    }
  }

  .tutorialModalWrapper {
    -webkit-box-shadow: 5px 5px 23px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 23px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 70%;
    height: 70%;
    min-height: 250px;
    position: absolute;
    top: 10%;
    display: grid;
    grid-template-columns: 1fr minmax(20px, 0.1fr);
    grid-template-rows: minmax(20px, 0.15fr) 1fr;
    background-color: #fff;

    .closeTutorial {
      display: block;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      width: 35px;
      height: 35px;
      align-self: center;
      justify-self: center;
      z-index: 1000;

      .close {
        width: 100%;
        height: 100%;
      }
    }

    .tutorial {
      display: flex;
      align-items: flex-end;
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      padding: 0.1rem;
      border-radius: 5px;
    }
  }

  // The min device height media querie ensures that the device is not a cell phone.
  @media only screen and (min-device-width: 480px) and (min-device-height: 450px) and (orientation: landscape) {
    display: block;
    .chartComponentInnerWrapper {
      display: flex;
      justify-content: space-around;
      align-items: initial;
      margin: 0;
      padding: 0.1rem 1.4rem 0.5rem;
    }
  }

  .tutorialModalWrapper {
    height: 75%;
    left: 15%;
    .closeTutorial {
      .close {
      }
    }

    .tutorial {
      width: 100%;
    }
  }

  @media only screen and (min-device-width: ${({ theme }) => theme.tablet}) {
    width: 100%;

    .chartComponentInnerWrapper {
      display: flex;
      align-items: flex-start;

      .chartComponent {
        position: static;
        height: 100%;
        width: 100%;
      }
    }
  }
`;

export default ChartStyled;
