import styled from '@emotion/styled';

const ChartFooterStyled = styled.footer`
  width: 100%;
  height: 100%;
  max-height: 22px;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 2px;
  margin-top: 0.2rem;
  padding: 0.025rem 1rem;
  display: flex;
  align-items: center;

  &.resultPanelContext {
    background-color: ${({ theme }) => theme.colors.grey400};
  }

  .source {
    margin: 0;
    font-size: 0.7rem !important;
  }

  .svg {
    width: 8%;
  }

  @media only screen and (min-device-width: ${({ theme }) => theme.tablet}) {
    align-items: center;

    .source {
      font-size: 0.8rem !important;
    }
  }
`;

export default ChartFooterStyled;
