import { useEffect, useState } from 'react';
import getIframeParams from '../Utils/getIframeParams';

const useSetMatomoInfo = ({ categoryId }) => {
  const params = getIframeParams();
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [parentURL, setParentURL] = useState();

  useEffect(() => {
    // In case A/B Testing is implemented.
    const urlHasInviteString = params?.referrer?.split('?').includes('wasUserInvited=true');
    if (params === undefined || params === null) {
      return;
    }
    if (params?.ab === 'true') {
      const isVisible = params.visible === 'true';
      document.cookie = `visible=${String(isVisible)}; SameSite=None; Secure`;
      document.cookie = `wasUserInvited=${urlHasInviteString.toString()}; SameSite=None; Secure`;
    }
    if (params?.ab === 'true' && !categoryId && !urlHasInviteString && params.visible === 'false') {
      setIsComponentVisible(false);
    }

    if (params?.parentURL) {
      setParentURL(params?.parenturl);
    } else {
      setParentURL(params?.referrer);
    }
  }, [categoryId, params, setIsComponentVisible, setParentURL, params.visible, params.referrer]);

  return {
    isComponentVisible,
    parentURL,
  };
};

export default useSetMatomoInfo;
