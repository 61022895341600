import clsx from 'clsx';
import Image from '../ImageComponent/Image';
import GIFComponentStyled from './GIFComponentStyled';

function GIFComponent({ className, src, alt, isTouchDevice }) {
  return (
    <GIFComponentStyled className={className}>
      {className === 'turnYourPhone' && <h2 className="tutorial">Line Challenge</h2>}
      <div className={clsx('tutorialContainer', !isTouchDevice && 'flex align-items-end')}>
        <Image src={src} alt={alt} className={clsx('image', className)} loading="lazy" />
      </div>
    </GIFComponentStyled>
  );
}

export default GIFComponent;
