// eslint-disable-next-line no-unused-vars
import { Theme } from '@emotion/react';

const theme = {
  colors: {
    // dpa greens
    dpa_luminousgreen: '#00e281',
    dpa_green: '#00b064',
    dpa_nightgreen: '#006345',
    dpa_lightgreen: '#00E281',

    // greyscales
    white: '#fff',
    grey100: '#f5f5f5',
    grey200: '#eee',
    grey300: '#e3e3e3',
    grey400: '#bababa',
    grey500: '#8c8c8c',
    grey600: '#7e7e7e',
    grey700: '#757575',
    grey800: '#4d4d4d',
    grey900: '#212121',

    // additional colors
    yellow: '#f9f500',
    yellow_dark: '#c1be01',
    orange: '#ffb300',
    orange_dark: '#c68400',
    pink: '#f5138d',
    pink_dark: '#bd0060',
    red: '#e53935',
    red_dark: '#ab000c',
    purple: '#8e24aa',
    purple_dark: '#5c007a',
    cyan: '#00acc1',
    cyan_dark: '#017c91',
    turquoise: '#00897b',
    turqoise_dark: '#005b4f',

    // dpa ID colors
    dpaid_luminousblue: '#0045f4',
    dpaid_blue: '#003edb',
    dpaid_nightblue: '#0030aa',
  },

  tablet: '768px',
  ipad_air: '820px',
  desktop: '992px',
  desktop_break_1: '1024px',
  desktop_break_2: '1295px',
  desktop_break_3: '1309px',
  fontWeight: {
    regular: '400',
    bold: '600',
    extraBold: '700',
  },
  fontFamily: {
    default: 'Futura PT',
    secondary: 'Arial',
  },
  fontSize: {
    verySmall: '0.75rem',
    small: '1rem',
    medium: '1.12rem',
    big: '1.25rem',
    keyword: '0.8rem',
    h2_mobile: '3rem',
    h3_mobile: '1.7rem',
  },
  spacing: {
    xxxs: '0.025rem',
    xxs: '0.5rem',
    xs: '1rem',
    s: '2rem',
    m: '3rem',
    l: '6rem',
  },
  border: {
    grey: '1px solid #444',
  },
  transition: {
    color_hover: 'color 0.2s linear',
  },
};

export default theme;
