import clsx from 'clsx';
import { useEffect } from 'react';
import RestartButton from '../Button/RestartButton';
import TutorialTogglerButton from '../Button/TutorialTogglerButton';
import ChartTitleSubtitle from './ChartTitle&Subtitle.styled';

function ChartTitleAndSubtitle({
  headerDescription,
  chartContenfulData,
  setRestartGame,
  userHasFinished,
  showModal,
  setShowTutorialModal,
  showTutorialModal,
  userHasPlayed,
  name,
}) {
  useEffect(() => {
    const escFunction = e => {
      if (e.key === 'Escape') {
        setShowTutorialModal(false);
        return;
      }
      if (e.key === 'Enter') {
        setShowTutorialModal(true);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction);
  }, [setShowTutorialModal, userHasFinished]);

  return (
    <ChartTitleSubtitle className={clsx('chartTitle', chartContenfulData?.showHeaderDescription && 'headerDescriptionContext')}>
      <div className="titleDescriptionWrapper">
        <h2 className="title">{name}</h2>
        <div className={clsx(userHasFinished && !showModal && 'HIDDEN', 'buttonWrapper flex')}>
          {!showTutorialModal && <TutorialTogglerButton setShowTutorialModal={setShowTutorialModal} userHasPlayed={userHasPlayed} />}
          {!userHasFinished && !showModal && <RestartButton setRestartGame={setRestartGame} />}
        </div>
      </div>
      <h3 className={clsx('subTitle', !chartContenfulData?.showHeaderDescription && 'HIDDEN')}>{headerDescription}</h3>
    </ChartTitleSubtitle>
  );
}

export default ChartTitleAndSubtitle;
