import clsx from 'clsx';
import ButtonStyled from './Button.styled';
import Image from '../ImageComponent/Image';

function TutorialTogglerButton({ setShowTutorialModal, userHasPlayed }) {
  const toggleTutorialModal = () => {
    setShowTutorialModal(true);
  };

  return (
    <ButtonStyled onClick={toggleTutorialModal} type="button" className={clsx('tutorialButton', userHasPlayed && 'visibility-hidden')}>
      <Image src="/icons/help.svg" alt="help Icon" className="help" />
    </ButtonStyled>
  );
}

export default TutorialTogglerButton;
