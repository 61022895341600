import styled from '@emotion/styled';

const TutorialStyled = styled.div`
  position: relative;
  display: inline-block;
  align-self: center;

  .tooltiptext {
    visibility: hidden;
    width: 50px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 105%;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.feedback {
    .tooltiptext {
      width: 80px;
      left: 65%;
    }
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export default TutorialStyled;
