/* eslint-disable no-console */
import { createClient } from 'contentful';
import { useEffect } from 'react';
import { calculateIndexForTodaysChart } from '../Utils/chartMath';

export const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const useGetContentfulData = ({ setChartContenfulData, setErrorMessage, categoryId }) => {
  useEffect(() => {
    // Careful! months are cero based, then january = 0, february = 1, etc.
    const index = categoryId || calculateIndexForTodaysChart(2022, 8, 28);
    const getContentfulData = async () => {
      const contentfulData = await client?.getEntries({
        'fields.id': index,
        content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE_ID,
      });
      if (Object.keys(contentfulData.items[0])?.length > 0) {
        setChartContenfulData(contentfulData.items[0]?.fields);
        return contentfulData;
      }
      setChartContenfulData(contentfulData?.items);
      return contentfulData;
    };
    getContentfulData()
      .then()
      .catch(error => {
        console.log('There is no Chart that matches this ID', error);
        setErrorMessage('Ohh! Es ist leider ein Fehler bei uns aufgetreten. Bitte lade die Seite erneut oder versuche es später noch einmal');
      });
  }, [categoryId, setChartContenfulData, setErrorMessage]);
};

export default useGetContentfulData;
