import { calculateValueInChartUnits, isClickValidYAxis, createColorArray, createLineWidthArray, enableTooltip, cutLabelsInHalf } from './chartMath';
import theme from '../globalStyles/theme';

function checkAxisLabelLength(axisLabel) {
  let newAxisLabel;
  if (axisLabel?.split(' ').length > 4 || axisLabel?.length > 40) {
    newAxisLabel = axisLabel?.split(' ').slice(0, 3).join(' ');
    return newAxisLabel;
  }
  return axisLabel;
}

export const chartConfig = {
  curveOne: {
    label: 'Challenge Curve',
    borderColor: `${theme.colors.dpa_nightgreen}`,
    backgroundColor: `${theme.colors.dpa_nightgreen}`,
  },
  curveTwo: {
    label: 'User Curve',
    borderColor: `${theme.colors.dpa_lightgreen}`,
    backgroundColor: `${theme.colors.dpa_lightgreen}`,
  },
  curveThree: {
    label: 'Preview',
    borderColor: `${theme.colors.dpa_lightgreen}`,
    backgroundColor: `${theme.colors.dpa_lightgreen}`,
    legend: {
      display: false,
    },
  },
  fonts: {
    family: 'futura-pt',
    size: 18,
    color: '#777',
  },
  options: {
    animation: {
      duration: 0,
    },
    title: {
      titleText: 'Klicken Sie auf die dunkle vertikale Linie, um die Grafik Ihrer Einschätzung nach fortzuführen.',
      fontSize: 25,
    },
    legend: {
      display: false,
      position: 'right',
      labels: {
        fontColor: '#000',
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
    tooltips: {
      enabled: false,
    },
  },
};

export const getChartOptions = (
  setUserPoints,
  userPoints,
  labels,
  setIsMouseOverLabel,
  isMouseOverLabel,
  nextLabelPoint,
  chartData,
  setProjectedLineValue,
  width,
  isTouchDevice,
  chartContenfulData,
  userHasFinished,
  chartDynamicData,
  showModal,
  isCurveBeingBuild,
  initialCurveIsBuild,
  setUserHasHoveredOverTheChart,
  userHasHoveredOverTheChart,
) => ({
  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'hover', 'touchend'],
  interaction: {
    mode: userHasFinished ? 'point' : 'nearest',
    axis: 'x',
    intersect: false,
  },
  onClick(event) {
    if (!isClickValidYAxis(chartContenfulData?.yMax, chartContenfulData?.yMin, event) || userPoints.length === labels.length || !isMouseOverLabel) return;
    setUserPoints(pointsToSet => [
      ...pointsToSet,
      calculateValueInChartUnits(
        chartContenfulData?.yMax,
        chartContenfulData?.yMin,
        event?.y,
        event?.chart,
        chartContenfulData?.numberOfDecimalsInUserPoints,
        chartContenfulData?.correctOffsetYAxis,
      ),
    ]);
    setIsMouseOverLabel(false);
  },
  onHover(event) {
    if (!userHasHoveredOverTheChart && initialCurveIsBuild && isMouseOverLabel) {
      setUserHasHoveredOverTheChart(true);
    }
    if (!initialCurveIsBuild) {
      setProjectedLineValue({ value: null });
      return;
    }
    if (event.x > nextLabelPoint - 12 && event.x < nextLabelPoint + 12 && isClickValidYAxis(chartContenfulData?.yMax, chartContenfulData?.yMin, event)) {
      setProjectedLineValue({
        value: calculateValueInChartUnits(
          chartContenfulData?.yMax,
          chartContenfulData?.yMin,
          event?.y,
          event?.chart,
          chartContenfulData?.numberOfDecimalsInUserPoints,
          chartContenfulData?.correctOffsetYAxis,
        ),
      });
      setIsMouseOverLabel(true);
    } else {
      setIsMouseOverLabel(false);
    }
  },
  scales: {
    y: {
      suggestedMin: chartContenfulData?.yMin,
      suggestedMax: chartContenfulData?.yMax,
      title: {
        text: checkAxisLabelLength(chartContenfulData?.yAxisLabel),
        display: chartContenfulData?.displayYAxisLabel,
        font: {
          family: chartConfig.fonts.family,
          size: width < 600 ? 10 : 15,
          weight: 'bold',
        },
      },
      ticks: {
        stepSize: width < 745 ? chartContenfulData?.stepSizeMobile : chartContenfulData?.stepSizeTablet,
        font: {
          fontFamily: chartConfig.fonts.family,
          size: width < 745 ? '12rem' : '15rem',
        },
      },
      grid: {
        drawBorder: false,
        display: width < 200 || isTouchDevice,
      },
    },
    x: {
      ticks: {
        title: {
          text: checkAxisLabelLength(chartContenfulData?.xAxisLabel),
          display: width > 745 && chartContenfulData?.displayXAxisLabel,
          font: {
            family: chartConfig.fonts.family,
            size: width < 745 ? '12rem' : '16rem',
          },
        },
        font: {
          fontFamily: chartConfig?.fonts?.family,
          size: width < 745 ? '14rem' : '18rem',
        },
        fontFamily: chartConfig?.fonts?.family,
        major: {
          fontFamily: chartConfig?.fonts?.family,
        },
      },
      grid: {
        color: createColorArray(chartContenfulData?.labels, userPoints.length, '#D3D3D3', '#808080'),
        borderColor: 'black',
        tickColor: 'grey',
        fontFamily: chartConfig.fonts.family,
        lineWidth: createLineWidthArray(chartContenfulData?.labels, userPoints.length, 1, 2),
      },
    },
  },
  responsive: true,
  plugins: {
    customPlugin: {
      consoleText: 'testText',
    },
    title: {
      display: false,
      text: userPoints.length === cutLabelsInHalf(labels) ? chartConfig.options.title.titleText : ' ',
      font: { size: '13' },
    },
    legend: {
      display: chartContenfulData?.legend,
      position: chartContenfulData?.legend ? chartContenfulData?.legendPosition : '',
      labels: {
        fontColor: chartConfig.options.legend.labels.fontColor,
        font: {
          family: chartConfig.fonts.family,
          size: chartConfig.fonts.size,
        },
      },
    },
    tooltip: {
      animations: 0,
      enabled: enableTooltip(isMouseOverLabel, userHasFinished, width, chartDynamicData, labels, showModal, isCurveBeingBuild),
      xAlign: userPoints.length + 1 < labels.length ? 'left' : 'right',
      yAlign: 'bottom',
      titleMarginBottom: 0,
      callbacks: {
        title: value => value[0].formattedValue,
        label() {
          return null;
        },
      },
    },
  },
  layout: {
    padding: {
      left: chartConfig.options.layout.padding.left,
      right: chartConfig.options.layout.padding.right,
      bottom: chartConfig.options.layout.padding.bottom,
      top: chartConfig.options.layout.padding.top,
    },
  },
  tooltips: {
    mode: 'point',
    intersect: false,
  },
});

export const defineChartDefaultVariables = defaults => {
  const newDefaults = { ...defaults };
  newDefaults.font.family = chartConfig.fonts.family;
  newDefaults.font.size = chartConfig.fonts.size;
  newDefaults.font.color = chartConfig.fonts.color;
  return newDefaults;
};
